<template>
	<section class="container">
		<div class="row">
			<div class="col-12">
				<BaseTable :items="logs" :fields="fields" :isBusy="isBusy">
					<template v-slot:cell(companyLogs.timestamp)="data">
						{{ getDateAndTime(data.item.timestamp) }}
					</template>
					<template v-slot:cell(companyLogs.operationType)="data">
						{{ data.item.operationType }}
					</template>
					<template v-slot:cell(companyLogs.description)="data">
						{{ data.item.description }}
					</template>

					<template v-slot:cell(employee)="data">
						<template v-if="data.item.type == 'Medarbejder'">
							<router-link
								:to="{
									name: 'CompanyEmployeeInfo',
									params: { companyId: companyId, employeeId: getEmployeeId(data.item.data) },
								}"
								>{{ getEmployeeName(data.item.data) }}</router-link
							>
						</template>
					</template>

					<template v-slot:cell(companyLogs.person)="data">
						{{ data.item.person.email }}
					</template>

					<template #cell(data)="row">
						<b-button size="sm" @click="row.toggleDetails" class="mr-2">
							{{ row.detailsShowing ? $t('companyLogs.hide') : $t('companyLogs.show') }}
							{{ $t('companyLogs.details') }}
						</b-button>
					</template>

					<template #row-details="row">
						<template v-for="(i, key) in row.item.data" v-bind="key">
							<b-card>
								<b>{{ key }}</b
								>: {{ i }}
							</b-card></template
						>
						<b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
					</template>
				</BaseTable>
			</div>
		</div>
		<!-- {{ companyLogs }} -->
	</section>
</template>
<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import dateMixin from '@/mixins/dateMixin'
export default {
	name: 'CompanyLogs',
	components: {
		BaseTable,
	},
	mixins: [dateMixin],
	async mounted() {
		this.$parent.$emit('updateLoader')
		this.isBusy = true
		await this.$store.dispatch('companyVuex/getCompanyLogs', this.id)
		var promises = []
		this.employeePolicies.forEach((policy) => {
			promises.push(this.$store.dispatch('companyVuex/getEmployeePolicyLogs', policy.id))
		})
		await Promise.all(promises)
		this.isBusy = false
	},
	data() {
		return {
			disabled: true,
			clicked: false,
			isBusy: false,
			fields: [
				{
					key: 'companyLogs.timestamp',
					label: i18n.t('companyLogs.Timestamp'),
				},
				{
					key: 'type',
					label: 'Type',
				},
				{
					key: 'employee',
					label: 'Id',
				},
				{
					key: 'companyLogs.operationType',
					label: 'Handling',
					sortable: true,
				},
				{
					key: 'companyLogs.description',
					label: i18n.t('companyLogs.description'),
					sortable: true,
				},
				{
					key: 'companyLogs.person',
					label: i18n.t('companyLogs.person'),
					sortable: true,
				},
				{
					key: 'data',
					label: i18n.t('companyLogs.data'),
					sortable: true,
				},
			],
		}
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		companyLogs() {
			var logs = this.$store.getters['companyVuex/companyLogs']
			return logs.map((log) => {
				return { ...log, type: 'Virksomhed' }
			})
		},
		employeePolicyLogs() {
			var logs = this.$store.getters['companyVuex/employeePolicyLogs']
			return logs.map((log) => {
				return { ...log, type: 'Medarbejder' }
			})
		},
		employeePolicies() {
			var policies = this.$store.getters['companyVuex/employeePolicies']
			return policies
		},
		logs() {
			var arr = [...this.companyLogs, ...this.employeePolicyLogs]
			return arr.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
		},
		id() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
	},
	methods: {
		getEmployeeName(data) {
			var user = this.getEmployee(data)
			return user?.fullName ?? ' - '
		},
		getEmployee(data) {
			if (data.hasOwnProperty('employeeId')) {
				var employeeId = data?.employeeId
				if (employeeId) {
					var user = this.$store.getters['user/users'].find((user) => user.id === employeeId)
					return user
				} else return ' - '
			}
			if (data.hasOwnProperty('employeePolicy')) {
				var employeeId = data?.employeePolicy.employeeId
				if (employeeId) {
					var user = this.$store.getters['user/users'].find((user) => user.id === employeeId)
					return user
				} else return ' - '
			}
		},
		getEmployeeId(data) {
			if (data.hasOwnProperty('employeeId')) {
				return data?.employeeId ?? ' - '
			}
			if (data.hasOwnProperty('employeePolicy')) {
				return data?.employeePolicy?.employeeId ?? ' - '
			}
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/pages/companyAdd.scss';
</style>
